<template>
  <div>
    <div class="column">
      <div class="col">
        <q-slider
            v-model="form"
            :min="vmin"
            :max="vmax"
            :step="vstep"
            label
            label-always
            color="primary"
            snap
        />

      </div>
      <div class="col self-end" v-if="forcenext||dnext">
        <q-btn color="secondary"  push icon-right="navigate_next" :label="template_text.NEXT" @click="onNext"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    form: {
      handler: function(newValue) {
        this.$emit('input', newValue);
      },
      deep: true,
    }
  },

  name: "numslide_input",
  data(){
    return {
      form:this.value|this.attribut.vdefault,
      vmin:this.attribut.vmin||0,
      vmax:this.attribut.vmax||10000,
      vstep:this.attribut.vstep||1,
    }

  },
  computed:{
  },
  props:{
    value:Number,
    attribut:Object,
    forcenext:Boolean,
    dnext:Boolean,
    template_text:Object,
    mandatory:Boolean,
    language:String,
  },
  methods:{
    async validate(){
      return true
    },
    isMandatory(val){
      if(this.mandatory){
        return !!val || this.template_text.MANDATORYFIELD
      }
      return true
    },
    async onNext(){
      if(await this.validate()) this.$emit('next');

    }
  },
}
</script>

<style scoped>

</style>
